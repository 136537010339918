<template>
    <div class="page">
        <div style="background-color: #FFFFFF;">
            <div style="color: black;font-weight: bold;display: flex; align-items: center;"
                class="font-size16 main-title">
                创建长租活动
            </div>
            <div class="border-radius4 main-back" style="background: #FFFFFF;display: flex;">
                <el-button type="primary" size="small" @click="goBack">返回</el-button>
            </div>
            <meta content="no-referrer" name="referrer" />
            <div class="content-box section">
                <easy-card title="基本信息">
                    <el-form ref="form" :model="model" :rules="rules" label-width="100px" size="small">

                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="活动名称" prop="activityName" required>
                                    <el-col :span="20">
                                        <el-input v-model="model.activityName" placeholder="请输入活动名称"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="有效时段" prop="timeValues" required>
                                    <!-- <el-input v-model="model.address" placeholder="请输入详细地址"></el-input> -->
                                    <el-date-picker v-model="model.timeValues" align="right" end-placeholder="结束时间"
                                        range-separator="-" start-placeholder="开始时间" type="datetimerange"
                                        @change="changeDate" value-format="yyyy-MM-dd HH:mm:ss">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>

                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="选择套餐" prop="leaseId" required>
                                    <el-col :span="20">
                                        <el-select v-model="model.leaseId" placeholder="请选择套餐" style="width: 100%;">
                                            <el-option v-for="item, index in dataList" :key="index" :value=item.id
                                                :label="item.leaseName">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="活动价格" prop="price" required>
                                    <el-col :span="20">
                                        <el-input v-model="model.price" placeholder="请输入价格"></el-input>
                                    </el-col>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-button class="xxd-button_save" type="primary" @click="save">保存
                        </el-button>
                    </el-form>
                </easy-card>
            </div>
            <SuccessDialog v-model="showDialog" @closeDialog="closeSuccDialog" :title="'操作成功'" :tip="successTip" />
        </div>
    </div>
</template>


<script>
import {
    addLeaseActivity
} from "@/api/lease";

import {
    getLeaseList
} from "@/api/common";
// import { Message } from "element-ui";
import SuccessDialog from "@/components/SuccessDialog";

export default {
    data() {
        return {
            isLoading: false,
            dataList: [],
            ParkingLotId: '',
            model: {
                activityName: "",
                leaseId: "",
                price: "",
                timeValues: null,
                startTime: null,
                endTime: null
            },
            rules: {
                activityName: [{ required: true, message: "活动名称不能为空" }],
                leaseId: [{ required: true, message: "长租套餐不能为空" }],
                price: [{ required: true, message: "价格不能为空" }],
                timeValues: [{ required: true, message: "有效时段不能为空" }]
            },
            showDialog: false,
            successTip: ""
        };
    },
    components: {
        SuccessDialog
    },
    methods: {
        closeSuccDialog(e) {
            console.log(e);
            this.showDialog = false;
        },
        //返回停车订单页面
        goBack() {
            this.$router.back();
        },
        changeDate(e) {
            this.model.startTime = e[0];
            this.model.endTime = e[1];
        },
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    addLeaseActivity(this.model).then(res => {
                        if (res && res.code == 30) {
                            // Message.success("新增成功");
                            // this.$router.back();
                            this.showDialog = true;
                            this.successTip = "新增长租活动成功";
                        }
                    })
                }
            })
        }
    },
    async mounted() {
        const res = await getLeaseList({ pageNo: 1, pageSize: 100 });
        if (res && res.code == 30) {
            this.dataList = res.returnObject;
        }
    }
};
</script>

<style lang="less" scoped>
.record-tilte {
    margin: 14px 0;
}

.span-margin {
    margin-left: 4px;
}

.showPicture {
    display: flex;
    flex-direction: row;
}

.pictureBox {
    border: 1px solid #bbb;
    width: 100%;
}

.boxF1 {
    margin-right: 10px;
}

.boxF2 {
    margin-left: 10px;
}

.carNumber {
    width: 100%;
    line-height: 30px;
    text-align: center;
    background: #0768FD;
    color: #fff;
}

.inOrOutTime {
    background: #f0f1f5;
    // padding-left: 15px;
    width: 100%;
    display: inline-block;
    line-height: 30px;
}

.photo-width {
    margin: 12px 8px;

    .photo-item {
        width: 100%;
        //display: flex;

        .top-img {
            //width: 284px;
            //height: 184px;
            margin-right: 20px;
            cursor: pointer;
        }
    }
}

.table-list {
    margin-top: 20px;
}

/deep/ .el-button--text {
    color: #0768FD !important;
}

.main-title {
    padding-left: 18px;
    padding-top: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid #FAFAFA;
}

.main-back {
    padding-left: 18px;
    margin-top: 20px;
    padding-bottom: 2px;
}
</style>