<template>
    <div>
        <el-dialog
            :title="title"
            :visible.sync="value"
            width="30%"
            :modal="false"
            :before-close="handleClose">
            <span>{{ tip }}</span>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="handleClose">取 消</el-button> -->
                <el-button type="primary" @click="handleClose">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: "success-dialog",
    props: {
        value: Boolean,
        title:{
            type: String,
            default: "成功提示",
        },
        tip:{
            type: String,
            default: "操作成功",
        },
    },
    data() {
        return {
            parkingList: [],
            selectVal: '',
        }
    },
    watch: {
        value(newVal) {
            if (!newVal || newVal === '') {
                this.selectVal = '';
            }
        }
    },
    methods: {
        handleClose(){
            this.$emit('closeDialog', 1);
        },
    }
};
</script>

<style lang="less" scoped>
/deep/ .v-modal{
    background-color: #ffffff !important;
}
</style>